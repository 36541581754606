<template>
  <tbody :class="tbodyClass">
    <ui-expand-header
      :headers="headers"
      :inner-sort="innerSort"
      :is-open="isOpen"
      :is-sortable="isSortable"
      :has-expand-panel="hasExpandPanel"
      :is-expand-arrow-shown="!!isExpandArrowShown"
      :item-class="itemClass"
      :group="group"
      @onOpen="onOpenGroup($event, group)"
      @onChangeSort="onChangeSort"
    >
      <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ item }">
        <slot
          v-if="$scopedSlots[`header.${header.value}`]"
          :name="`header.${header.value}`"
          :item="item"
          :value="item[header.value]"
          :header="header"
        >
          {{ item[header.value] }}
        </slot>

        <slot
          v-else
          :name="`item.${header.value}`"
          :item="item"
          :value="getValue(item, header.value)"
        >
          {{ getValue(item, header.value) }}
        </slot>
      </template>
    </ui-expand-header>
    <template v-if="isOpen">
      <tr v-if="innerHeaders.length" class="inner-table-headers">
        <td v-for="(header, index) in filteredHeaders" :key="index">
          {{header.text}}
        </td>
      </tr>
      <template v-for="subItem in sortedSubItems(items)">
        <ui-expand-row
          :key="subItem.id"
          :headers="secondRowHeaders"
          :inner-sort="innerSort"
          :is-open="openedSecondGroupsId.includes(subItem.id)"
          :is-expand-arrow-shown="!!subItem[thirdGroupKey]?.length"
          :item-class="itemClass"
          :gr-ca-report="grCaReport"
          :group="subItem"
          multi-group-second-level
          @onOpen="onOpenSubGroup($event, subItem)"
        >
          <template v-for="subheader in secondRowHeaders" v-slot:[`subheader.${subheader.value}`]="{ item }">
            <slot
              v-if="$scopedSlots[`subheader.${subheader.value}`]"
              :name="`subheader.${subheader.value}`"
              :item="item"
              :value="item[subheader.value]"
              :header="subheader"
            >
              {{ item[subheader.value] }}
            </slot>

            <slot
              v-else
              :name="`item.${subheader.value}`"
              :item="item"
              :value="getValue(item, subheader.value)"
            >
              {{ getValue(item, subheader.value) }}
            </slot>
          </template>
        </ui-expand-row>
        <template v-if="openedSecondGroupsId.includes(subItem.id)">
          <template v-for="content in sortedSubItems(subItem[thirdGroupKey])">
            <ui-expand-row
              :key="content.id"
              :headers="headers"
              :inner-sort="innerSort"
              :is-open="openedThirdGroupsId.includes(content.id)"
              :is-expand-arrow-shown="!!filters.fourthGroupBy && 'fourth_group' in content"
              :item-class="itemClass"
              :gr-ca-report="grCaReport"
              :group="content"
              multi-group-third-level
              @onOpen="onOpenThirdGroup($event, content)"
            >
              <template v-for="subheader in headers" v-slot:[`subheader.${subheader.value}`]="{ item }">
                <slot
                  v-if="$scopedSlots[`thirdRow.${subheader.value}`]"
                  :name="`thirdRow.${subheader.value}`"
                  :item="item"
                  :value="item[subheader.value]"
                  :header="subheader"
                >
                  {{ item[subheader.value] }}
                </slot>
                <slot
                  v-else
                  :name="`item.${subheader.value}`"
                  :item="item"
                  :value="getValue(item, subheader.value)"
                >
                  {{ getValue(item, subheader.value) }}
                </slot>
              </template>
            </ui-expand-row>
            <template v-if="openedThirdGroupsId.includes(content.id) && filters.fourthGroupBy">
              <ui-expand-row
                v-for="subContent in sortedSubItems(content.fourth_group)"
                :key="subContent.id"
                :headers="filteredHeaders"
                :inner-sort="innerSort"
                :item-class="itemClass"
                :gr-ca-report="grCaReport"
                :group="subContent"
                multi-group-fourth-level
                :is-expand-arrow-shown="false"
                @onChangeSort="onChangeSort"
              >
                <template v-for="subheader in headers" v-slot:[`subheader.${subheader.value}`]="{ item }">
                  <slot
                    :name="`item.${subheader.value}`"
                    :item="item"
                    :value="getValue(item, subheader.value)"
                  >
                    {{ getValue(item, subheader.value) }}
                  </slot>
                </template>
              </ui-expand-row>
            </template>
          </template>
        </template>
      </template>
    </template>
    <slot name="append" />
  </tbody>
</template>

<script>
import UiExpandHeader from '@/components/ui/framework/general/UIExpandGroup/UIExpandHeader.vue'
import UiExpandRow from "@/components/ui/framework/general/UIExpandGroup/UIExpandRow.vue";
import {get, isString, orderBy} from 'lodash'
import { randomString, splitArr } from '@/helpers/functions'
import { alphabet } from '@/constants/alphabet'
export default {
  name: 'UiExpandGroup',
  components: {
    UiExpandRow,
    UiExpandHeader,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    openedGroups: {
      type: Array,
      default: () => ([]),
    },
    openedSubGroups: {
      type: Array,
      default: () => ([]),
    },
    openedThirdGroups: {
      type: Array,
      default: () => ([]),
    },
    headers: {
      type: Array,
      default: () => [],
    },
    innerHeaders: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    fixedColsCount: {
      type: Number,
      default: () => 0,
    },
    hasFixedCols: {
      type: Boolean,
      default: () => false,
    },
    innerSort: {
      type: Boolean,
      default: false,
    },
    valueMutator: {
      type: Function,
      default: () => null,
    },
    columnsToShow: {
      type: Array,
      default: () => [],
    },
    closeOnDataChange: {
      type: Boolean,
      default: false
    },
    grCaReport: {
      type: Boolean,
      default: false
    },
    disableSort: {
      type: Boolean,
      default: false
    },
    hideExpandArrow: {
      type: Boolean,
      default: false
    },
    hasExpandPanel: {
      type: Boolean,
      default: false
    },
    secondGroupKey: {
      type: String,
      default: 'second_group',
    },
    thirdGroupKey: {
      type: String,
      default: 'third_group',
    },
    itemClass: {
      type: Function,
      default: () => '',
    }
  },
  data() {
    return {
      isOpen: false,
      isSortable: false,
      filteredHeaders: [],
      currentIterItem: null,
      openedItemGroups: [],
      openedSecondGroupsId: [],
      openedThirdGroupsId: [],
    }
  },
  computed: {
    isExpandArrowShown(){
      return !this.hideExpandArrow && this.items.length
    },
    innerSortKey() {
      return this.options?.sortBy[0] || this.headers[0].value
    },
    sortedItems() {
      const orders = (this.options?.sortDesc[0] || this.isSortable) ? 'desc' : 'asc'
      return this.disableSort ? this.items : orderBy(this.items, [this.innerSortKey], [orders])
    },
    tbodyClass() {
      return randomString(alphabet, 8).join('')
    },
    secondRowHeaders(){
      return this.innerHeaders.length ? this.filteredHeaders : this.headers
    }
  },
  watch: {
    isOpen: {
      handler(val) {
        if (val && this.hasFixedCols) {
          setTimeout(() => {
            this.fixCols('.fixed-col.right.expanded-col')
          }, 0)
        }
      },
    },
    items: {
      handler() {
        if(this.closeOnDataChange){
          this.isOpen = false
        }
      },
    },
    columnsToShow(data) {
      this.setHeaders(data)
      this.detachCols()
      this.$nextTick(() => {
        this.fixCols()
      })
    },
    openedGroups: {
      handler(newVal){
        this.isOpen = newVal.includes(this.group.id)
      },
      immediate: true
    },
    openedSubGroups: {
      handler(newVal){
        this.openedSecondGroupsId = [...newVal]
      },
      immediate: true
    },
    openedThirdGroups: {
      handler(newVal){
        this.openedThirdGroupsId = [...newVal]
      },
      immediate: true
    },
  },
  created() {
    this.setHeaders(this.columnsToShow)
  },
  mounted() {
    if (this.hasFixedCols) {
      this.fixCols()
    }
  },
  methods: {
    sortedSubItems(arr) {
      const monthOrder = [
        'january', 'february', 'march',
        'april', 'may', 'june', 'july',
        'august', 'september', 'october',
        'november', 'december'
      ];
      const getMonthIndex = month => monthOrder.indexOf(month.toLowerCase())
      const getWeekStart = (weekRange) => this.$moment(weekRange.split(' - ')[0], 'DD.MM.YYYY');
      function sortGroupList(group, sortKey) {
        const value = get(group, sortKey)
        if (sortKey.includes('month')) {
          group.groups.monthIndex = value && isString(value) ? getMonthIndex(value) : -1;
          return group;
        } else if (sortKey.includes('week')) {
          return value && isString(value) ? getWeekStart(value) : value;
        } else {
          return value && isString(value) ? value.toLowerCase() : value;
        }
      }
      const sortKey = this.innerSortKey === 'groups.month' ? 'groups.month_title' : this.innerSortKey
      const orders = (this.options?.sortDesc[0] || this.isSortable) ? 'desc' : 'asc'
      const sortFunc = group => sortGroupList(group, sortKey)
      if(this.disableSort) return arr
      if(sortKey === 'groups.month_title'){
        orderBy(arr, sortFunc, orders)
        return orderBy(arr, [(i) => i.groups.year, (i) => i.groups.monthIndex], [orders, orders])
      } else {
        return orderBy(arr, sortFunc, orders)
      }
    },
    fixCols(classes = '.fixed-col.right') {
      const rows = splitArr(
        document.querySelectorAll(`.${this.tbodyClass} ${classes}`),
        this.fixedColsCount,
        true,
      )
      rows.forEach(row => {
        let prevElWidth = 0
        row.forEach((col, index) => {
          const rect = col.getBoundingClientRect()
          prevElWidth += rect.width

          if (index === 0) {
            col.style.right = '0px'
            return
          }

          let offset = rect.width
          let offsetNext = 0

          if (index > 0) offsetNext = Math.abs(offset - prevElWidth)
          col.style.right = offsetNext + 'px'
        })
      })
    },
    detachCols() {
      document.querySelectorAll(`.${this.tbodyClass} .fixed-col.right`).forEach(item => {
        item.style.right = null
      })
    },
    getValue(item, key) {
      const value = get(item, key)
      if (this.valueMutator) {
        return this.valueMutator({
          value,
          item,
          key,
        })
      }
      return value
    },
    onOpenGroup(isOpen, item) {
      this.isOpen = isOpen
      if (!isOpen && item.groups?.length) {
        item.groups?.forEach((group) => {
          this.openedSecondGroupsId = this.openedSecondGroupsId.filter(el => el !== group.id)
        })
      }
      this.$emit('openedGroup', isOpen, item.id)
    },
    onOpenSubGroup(isOpen, item) {
      if (isOpen) this.openedSecondGroupsId.push(item.id)
      if (!isOpen) this.openedSecondGroupsId = this.openedSecondGroupsId.filter(el => el !== item.id)
      this.$emit('openedSubGroup', isOpen, item.id)
    },
    onOpenThirdGroup(isOpen, item) {
      if (isOpen) this.openedThirdGroupsId.push(item.id)
      if (!isOpen) this.openedThirdGroupsId = this.openedThirdGroupsId.filter(el => el !== item.id)
      this.$emit('openedThirdGroup', isOpen, item.id)
    },
    onChangeSort(isSortable) {
      this.isSortable = isSortable
    },
    setHeaders(selectedHeaders = []) {
      let currentHeaders = this.innerHeaders.length ? this.innerHeaders : this.headers
      if (!selectedHeaders.length) {
        this.filteredHeaders = [...currentHeaders]
        return
      }
      const colIDs = selectedHeaders.map(selectedItem => selectedItem.value)
      this.filteredHeaders = currentHeaders.filter(item => {
        return !colIDs.includes(item.value)
      })
    },
  },
}
</script>

<style>
tr.inner-table-headers td {
  color: #AEAEAE!important;
  font-weight: 400!important;
  font-size: 12px!important;
  line-height: 20px!important;
}
</style>
