import { DATE_TIME_FORMAT } from '@/constants/date'
import moment from 'moment'
import i18n from '@/plugins/vue-i18n'

export const DATE_PRESETS = [
  {
    id: 'today',
    name: i18n.tc('base.presets.today'),
    dates: {
      start: moment().startOf('day').format(DATE_TIME_FORMAT),
      end: moment().endOf('day').format(DATE_TIME_FORMAT),
    },
  },
  {
    id: 'today-and-yesterday',
    name: i18n.tc('base.presets.todayAndYesterday'),
    dates: {
      start: moment().subtract(1, 'days').startOf('day').format(DATE_TIME_FORMAT),
      end: moment().endOf('day').format(DATE_TIME_FORMAT),
    },
  },
  {
    id: 'yesterday',
    name: i18n.tc('base.presets.yesterday'),
    dates: {
      start: moment().subtract(1, 'days').startOf('day').format(DATE_TIME_FORMAT),
      end: moment().subtract(1, 'days').endOf('day').format(DATE_TIME_FORMAT),
    },
  },
  {
    id: 'currentWeek',
    name: i18n.tc('base.presets.currentWeek'),
    dates: {
      start: moment().startOf('isoWeek').format(DATE_TIME_FORMAT),
      end: moment().format(DATE_TIME_FORMAT),
    },
  },
  {
    id: 'previousWeek',
    name: i18n.tc('base.presets.previousWeek'),
    dates: {
      start: moment().subtract(1, 'weeks').startOf('isoWeek').format(DATE_TIME_FORMAT),
      end: moment().subtract(1, 'weeks').endOf('isoWeek').format(DATE_TIME_FORMAT),
    },
  },
  {
    id: 'currentMonth',
    name: i18n.tc('base.presets.currentMonth'),
    dates: {
      start: moment().startOf('month').format(DATE_TIME_FORMAT),
      end: moment().format(DATE_TIME_FORMAT),
    },
  },
  {
    id: 'previousMonth',
    name: i18n.tc('base.presets.previousMonth'),
    dates: {
      start: moment().subtract(1, 'months').startOf('month').format(DATE_TIME_FORMAT),
      end: moment().subtract(1, 'months').endOf('month').format(DATE_TIME_FORMAT),
    },
  },
  {
    id: 'currentYear',
    name: i18n.tc('base.presets.currentYear'),
    dates: {
      start: moment().startOf('year').format(DATE_TIME_FORMAT),
      end: moment().format(DATE_TIME_FORMAT),
    },
  },
  {
    id: 'previousYear',
    name: i18n.tc('base.presets.previousYear'),
    dates: {
      start: moment().subtract(1, 'year').startOf('year').format(DATE_TIME_FORMAT),
      end: moment().subtract(1, 'year').endOf('year').format(DATE_TIME_FORMAT),
    },
  },
]
