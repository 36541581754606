<template>
  <v-text-field
    ref="ui-input"
    v-model="getValue"
    :error-messages="getErrorMsg"
    :error="error"
    outlined
    shaped
    :hide-details="hideDetails"
    v-bind="$attrs"
    v-on="$listeners"
    :background-color="backgroundColor"
    class="ui-text-field"
    :class="{
      'background-custom': backgroundColor,
      'custom-value': !isDefaultValue,
    }"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </v-text-field>
</template>

<script>
import { getErrorMsg } from '@/helpers/validation'
import IMask from 'imask'

export default {
  name: 'ui-text-field',
  props: {
    value: null,
    errorMessages: {
      type: [String, Array, Object],
    },
    hideDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
    isDefaultValue: {
      type: Boolean,
      default: true,
    },
    mask: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      maskValue: null,
    }
  },
  computed: {
    getValue: {
      get() {
        if (this.mask && this.maskValue) {
          this.maskValue.updateValue()
          return this.maskValue?.value
        }
        return this.value
      },
      set(v) {
        if (this.mask) {
          if(this.maskValue) this.maskValue.updateValue()
          this.$emit('input', v.replace(/\s/g, ''))
        } else this.$emit('input', v)
      },
    },
    error() {
      if (!this.value) return this.$attrs.error
      return this.value.$error
    },
    getErrorMsg() {
      if (
        Array.isArray(this.errorMessages) ||
        typeof this.errorMessages === 'string'
      ) {
        return this.errorMessages
      }
      return getErrorMsg(this.errorMessages)
    },
  },
  mounted() {
    if (this.mask) {
      const maskOptions = {
        mask: Number,
        scale: 0,
        signed: false,
        thousandsSeparator: ' ',
        padFractionalZeros: true,
      }
      const input = this.$refs['ui-input'].$refs.input
      this.maskValue = IMask(input, maskOptions)
    }
  },
}
</script>

<style lang="scss">
.ui-text-field {
  .v-label--active {
    color: var(--v-black-darken3) !important;
  }

  fieldset,
  .v-text-field .v-input__control {
    border-color: var(--v-black-lighten6);
  }

  .v-text-field__slot {
    .v-label {
      font-size: 14px !important;
      color: var(--v-black-darken1);
    }
    .v-text-field__prefix {
      font-size: 12px !important;
      color: var(--v-black-darken3);
    }
    input {
      font-size: 12px;
      color: var(--v-black-darken3) !important;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: var(--v-black-darken3);
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .v-input__append-inner {
    margin-top: 12px !important;
  }

  &.background-custom {
    .v-text-field__slot input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: var(--v-black-darken3);
        -webkit-box-shadow: 0 0 0 1000px #ffffff00 inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }

  &.custom-value .v-text-field__slot input {
    color: var(--v-blue500-base) !important;
  }
}

.ui-text-field__append-outer-reset {
  .v-input__append-outer {
    margin-top: 0 !important;
  }
}
</style>
