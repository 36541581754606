<template>
  <div class="modal-wrapper__container">
    <div class="modal-wrapper__wrap">
      <div class="modal-wrapper__window elevation-20" :style="styleWindow">
        <button v-if="showClose" class="modal-wrapper__close" @click="onClose">
          <v-icon dense>mdi-close</v-icon>
        </button>

        <div class="modal-wrapper__inner">
          <header v-if="$scopedSlots.header" class="modal-wrapper__header">
            <slot name="header" />
          </header>

          <div class="modal-wrapper__content" :class="{'no-padding': noPadding}">
            <slot />
          </div>

          <footer v-if="$scopedSlots.footer" class="modal-wrapper__footer">
            <slot name="footer" />
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalWrapper',

  props: {
    maxWidth: {
      type: String,
      default: '410px',
    },
    background: {
      type: String,
      default: '#fff',
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    styleWindow() {
      return [
        {
          maxWidth: this.maxWidth,
          background: this.background,
        },
      ]
    },
  },

  methods: {
    onClose(event) {
      this.$emit('close', event)
    },
  },
}
</script>
<style lang="scss" scoped>
.modal-wrapper {
  &__container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;

    background-color: rgba(0, 0, 0, 0.3);
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
  }

  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    min-height: 100vh;
  }

  &__window {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    max-height: 100%;
    border-radius: 16px;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 3;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    cursor: pointer;
  }

  &__close__icon {
    pointer-events: none;
    width: 24px;
    height: 24px;
  }

  &__inner {
    min-height: 100%;

    &--noindent {
      padding: 0;
    }
  }

  &__header {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    padding: 24px;
    color: var(--v-black-darken6);
  }

  &__content {
    height: 100%;
    padding: 0 24px 24px;
    &.no-padding {
      padding: 0;
    }
  }

  &__content__center {
    text-align: center;
  }

  &__footer {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #f4f4f4;

    > *:not(:first-child) {
      margin-left: 16px;
    }
  }
}
</style>
