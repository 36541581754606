import moment from "moment";
import {DATE_FORM_FORMAT} from "@/constants/date";

export const defaultFilters = () => ({
  date: moment().startOf('month').format(DATE_FORM_FORMAT),
  partnerGroups: null,
  sortBy: ['actual_budget'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
    showCountriesNoSpend: false,
  }
})