import SuperRepository from '../superRepository'

export default new (class PartnersStreamRepository extends SuperRepository {
  baseUri() {
    return 'partner_stream'
  }
  updatePartnerStream = async ({ id, ...params }) =>
    this.httpClient().put(`${this.baseUri()}/${id}`, params)
  validateOffer = async (id) =>
    this.httpClient().get(`${this.baseUri()}/${id}/validate_offer`)
})()
