<template>
  <tr :class="getItemClass(group)">
    <td v-if="grCaReport" style="border-bottom: 1px var(--v-black-lighten6) solid"></td>
    <td v-if="grCaReport && (multiGroupThirdLevel || multiGroupFourthLevel)"
        style="border-bottom: 1px var(--v-black-lighten6) solid"></td>
    <td v-if="grCaReport && multiGroupFourthLevel" style="border-bottom: 1px var(--v-black-lighten6) solid"></td>
    <td :class="getHeaderClasses(headers[0])" :style="getCellStyles(headers[0])">
      <div v-if="isExpandArrowShown" class="flex-nowrap d-flex align-start py-1 container-subheader">
        <v-icon
          color="#AEAEAE"
          class="mr-4"
          style="margin-top: -2px"
          @click="onOpenGroup"
        >
          {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
        <slot :name="`subheader.${expandColumnName}`" :item="group" :value="group[expandColumnName]">
          <span>
            {{ group[expandColumnName] }}
          </span>
        </slot>
      </div>
      <template v-else>
        <slot :name="`subheader.${expandColumnName}`" :item="group" :value="group[expandColumnName]">
          {{ group[expandColumnName] }}
        </slot>
      </template>
    </td>

    <td
      v-for="(subheader, index) in getGroupHeaders(headers)"
      :key="`group-subheader-cell-${index+1}`"
      :class="getHeaderClasses(subheader)"
      :style="getCellStyles(subheader)"
    >
      <slot :name="`subheader.${subheader.value}`" :item="group" :value="group[subheader.value]">
        {{ group[subheader.value] }}
      </slot>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'UiExpandRow',
  props: {
    group: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    innerSort: {
      type: Boolean,
      default: false,
    },
    itemClass: {
      type: Function,
      default: () => '',
    },
    isExpandArrowShown: {
      type: Boolean,
      default: true
    },
    grCaReport: {
      type: Boolean,
      default: false,
    },
    multiGroupSecondLevel: {
      type: Boolean,
      default: false,
    },
    multiGroupThirdLevel: {
      type: Boolean,
      default: false,
    },
    multiGroupFourthLevel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    levelNumber() {
      if (!this.grCaReport) return 0
      if (this.multiGroupThirdLevel) return 2
      if (this.multiGroupFourthLevel) return 3
      return 1
    },
    expandColumnName() {
      return this.headers[this.levelNumber].value
    },
  },
  methods: {
    getItemClass(item) {
      return this.itemClass ? this.itemClass(item) : ''
    },
    getCellStyles(header) {
      const defaultStyles = {
        background: '#fff',
        borderBottom: '1px var(--v-black-lighten6) solid',
      }

      if (!header.width) {
        return defaultStyles
      }

      return {
        ...defaultStyles,
        width: header.width,
        minWidth: header.width,
        maxWidth: header.width,
      }
    },
    getHeaderClasses(header) {
      const hasFixedEls = header.fixed && header.fixed.side
      return [
        {'v-data-table__divider': header.divider},
        {'fixed-col expanded-col': hasFixedEls},
        `${hasFixedEls ? header.fixed.side : ''}`,
        header.class,
        header.align ? 'text-' + header.align : '',
        {'multirow__second-row': this.grCaReport && this.multiGroupSecondLevel},
        {'multirow__third-row': this.grCaReport && this.multiGroupThirdLevel},
        {'multirow__fourth-row': this.grCaReport && this.multiGroupFourthLevel},
      ]
    },
    getGroupHeaders(headers) {
      return headers.slice(this.levelNumber + 1, headers.length)
    },
    onOpenGroup() {
      this.$emit('onOpen', !this.isOpen)
    },
  },
}
</script>
<style lang="scss" scoped>
.container-header {
  position: relative;
}

.ui-data-table tbody > tr > td.multirow__second-row {
  background-color: var(--v-violet50-base) !important;
  border-bottom: 1px solid var(--v-violet200-base) !important;
}

.ui-data-table tbody > tr > td.multirow__third-row {
  background-color: var(--v-teal50-base) !important;
  border-bottom: 1px solid var(--v-teal200-base) !important;
}

.ui-data-table tbody > tr > td.multirow__fourth-row {
  background-color: var(--v-orange50-base) !important;
  border-bottom: 1px solid var(--v-orange200-base) !important;
}
</style>
