import SuperRepository from '../superRepository'

export default new (class PlayersReportsRepository extends SuperRepository {
  baseUri() {
    return 'player_report'
  }
  playerReportNotification = async () =>
    this.httpClient().get(`${this.baseUri()}/notification`)
  generalReport = async params =>
    this.httpClient().post(`${this.baseUri()}/general_report_new`, params)
  generalReportClick = async params =>
    this.httpClient().post(`${this.baseUri()}/general_report_click`, params)
  cohortAnalysis = async params =>
    this.httpClient().post(`${this.baseUri()}/cohort_analysis`, params)
  costsReportExport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/cost_report`, data, params)
})()