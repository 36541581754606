import Vue from 'vue'

import { getDateByTimeZone, formatDate, removeOffsetTime } from '@/helpers/date'
import DateConverter from '@/models/DateConverter'
import { formatAsPercentage, formatNumber } from '@/helpers/number'
import {
  numberWithSpaces,
  removeCents,
  renderActive,
  renderArrayOfObjectsByKey,
  renderValue,
  renderValueByKey,
  renderValueJoinBySymbol,
  renderValueYesNo,
} from '@/helpers/render-value'
import { stringCroppedView } from "@/helpers/cropString";
import {prepareGeneralReportData} from "@/helpers/general-report";

Vue.filter('getDateTimeZone', str => getDateByTimeZone(str))
Vue.filter('getDateTimezoneOffset', DateConverter.convertUtcToTimezone)
Vue.filter('formatDate', formatDate)
Vue.filter('convertToLocalDate', DateConverter.convertDateToUserDateFormatted)
Vue.filter('removeTimeOffset', removeOffsetTime)
Vue.filter('formatAsPercentage', formatAsPercentage)
Vue.filter('formatNumber', formatNumber)
Vue.filter('removeCents', removeCents)
Vue.filter('renderValue', renderValue)
Vue.filter('renderValueByKey', renderValueByKey)
Vue.filter('renderValueYesNo', renderValueYesNo)
Vue.filter('renderActive', renderActive)
Vue.filter('renderValueJoinBySymbol', renderValueJoinBySymbol)
Vue.filter('renderArrayOfObjectsByKey', renderArrayOfObjectsByKey)
Vue.filter('cropString', stringCroppedView)
Vue.filter('generalReportList', prepareGeneralReportData)
Vue.filter('numberWithSpaces', numberWithSpaces)
