export default [
  {
    path: '/general-report',
    name: 'pageGeneralReportList',
    meta: { title: 'generalReport.listTitle', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['general_report'] },
    component: () => import('@/pages/general-report'),
  },
  // {
  //   path: '/general-report-old',
  //   name: 'pageGeneralReportListOld',
  //   meta: { title: 'generalReport.listTitleOld', roles: ['ADMIN', 'TEAM_LEADER'], permissions: ['general_report'] },
  //   component: () => import('@/pages/general-report-old'),
  // },
  {
    path: '/general-report-click',
    name: 'pageGeneralReportListClick',
    meta: { title: 'generalReport.listTitleClick', roles: ['ADMIN'] },
    component: () => import('@/pages/general-report-old'),
  },
]