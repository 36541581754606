import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import {defaultFilters} from '@/models/CohortAnalysis/helpers'
import TableOptions from '@/models/TableOptions'
import {getPaginatedList, getSortedItems, mockPagination, omitEmptyDeepFilters, prepareFilters} from '@/helpers/filters'
import playerReportsRepository from "@/repository/generatedRepository/playerReportsRepository";
import i18n from "@/plugins/vue-i18n";
import moment from "moment/moment";
import {generatePassword} from "@/helpers/functions";
import store from "@/store";
import {WIDGET_REPORTS} from "@/constants/widgetReports";
import {DATE_TIME_FORM_SHORT_FORMAT_DAY_FIRST} from "@/constants/date";
import { checkSortBy } from '@/constants/generalReport'
import { cloneDeep } from 'lodash'
import { canFitInLocalStorage, defineGroupId } from '@/helpers/general-report'

const state = {
  list: [],
  listReport: [],
  paginatedList: [],
  notUsedSpend: [],
  total: {},
  playersCount: 0,
  filters: StorageService.get(storageKeys.COHORT_ANALYSIS_FILTERS) || {...defaultFilters()},
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  total: state => state.total,
  list: state => state.list,
  listReport: state => state.listReport,
  playersCount: state => state.playersCount,
  notUsedSpend: state => state.notUsedSpend,
  paginatedList: state => state.paginatedList,
  listPrepared: state =>
    [...getSortedItems(state.list, {
      sortBy: state.filters.sortBy,
      sortDesc: state.filters.sortDesc,
    }), ...state.notUsedSpend],
  listPaginated: state =>
    [...getPaginatedList(state.paginatedList, state.tableOptions.pagination, {
      sortBy: state.filters.sortBy,
      sortDesc: state.filters.sortDesc,
    }), ...(state.tableOptions.page === state.tableOptions.totalItems ? state.notUsedSpend : [])],
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, filtersData) {
    const {updateData = true, signal, ...filters} = filtersData
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.COHORT_ANALYSIS_FILTERS, filters)
    const appliedFilters = omitEmptyDeepFilters(filters.filter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    let data = {}
    if (StorageService.get(storageKeys.COHORT_ANALYSIS_DATA) && !updateData) {
      data = StorageService.get(storageKeys.COHORT_ANALYSIS_DATA)
    } else {
      const result = await store.dispatch('ReportNotificationModule/loadNotification')
      const reportCacheUpdated = Object.keys(result.reportNotifications).every(v => !result.reportNotifications[v])
      await store.dispatch('ReportNotificationModule/updateLocalStorage', {
        reportName: WIDGET_REPORTS.COHORT_ANALYSIS,
        reportCacheState: result.reportNotifications,
        reportBuiltAt: moment().format(DATE_TIME_FORM_SHORT_FORMAT_DAY_FIRST),
        reportCacheUpdated,
        ...(reportCacheUpdated ? {widget2_closed: true} : {})
      })
      data = (await playerReportsRepository.cohortAnalysis({
        ...preparedFilters.filter, signal})).data
    }
    if (canFitInLocalStorage(data)) {
      StorageService.set(storageKeys.COHORT_ANALYSIS_DATA, data)
    } else {
      StorageService.remove(storageKeys.COHORT_ANALYSIS_DATA)
    }
    context.commit('setReportData', { data, filters })
    context.commit('setList', { data, filters })
    context.commit('setPaginatedList', { data, pagination: mockPagination(data.rows, filters), filters })
    return {products: data.items}
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.COHORT_ANALYSIS_FILTERS)
  },
  updateFiltersClientOptions({commit, getters}, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.COHORT_ANALYSIS_FILTERS, updatedFilters)
  },
  updatePaginationGeneralReport(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.COHORT_ANALYSIS_FILTERS, filters)
    context.commit('setPagination', filters.pagination)
  },
  updateSortGeneralReport(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.COHORT_ANALYSIS_FILTERS, filters)
    context.commit('setSort', {
      sortBy: filters.sortBy,
      sortDesc: filters.sortDesc,
    })
  }
}

const mutations = {
  setList(state, {data, pagination, filters}) {
    if (data === null) return state.list = []
    let convArr = null
    const firstGroupBy = filters.filter.groupBy
    const secondGroupBy = filters.filter.secondGroupBy || 'not_used_spend'
    if (Object.values(data.not_used_spend).length === 1 && !filters.filter.secondGroupBy) {
      let item = Object.values(data.not_used_spend)[0]
      const resString = 'NUS: ' + item.country + ', ' + item.partner + ', ' + item.stream + ', ' + moment(item.date).format('DD.MM.YYYY')
      item.groups = {
        [defineGroupId(firstGroupBy)]: i18n.tc('generalReport.table.notUsedSpend'),
      }
      item.second_group = []
      item.spend = item.price
      item.spend_2 = item.price_2
      item.new_deposits = 'empty'
      if (secondGroupBy === 'not_used_spend') {
        item.not_used_spend = resString
      } else {
        item.groups[defineGroupId(secondGroupBy)] = resString
      }
      convArr = item
    } else {
      convArr = {
        groups: {[defineGroupId(firstGroupBy)]: i18n.tc('generalReport.table.notUsedSpend')},
      }
      convArr.second_group = Object.values(data.not_used_spend).map(item => {
        const resString = 'NUS: ' + item.country + ', ' + item.partner + ', ' + item.stream + ', ' + moment(item.date).format('DD.MM.YYYY')
        item.groups = {
          [defineGroupId(secondGroupBy)]: null
        }
        item.spend = item.price
        item.spend_2 = item.price_2
        item.new_deposits = 'empty'
        if (secondGroupBy === 'not_used_spend') {
          item.not_used_spend = resString
        } else {
          item.groups[defineGroupId(secondGroupBy)] = resString
        }
        return item
      })
      convArr.spend = Object.values(data.not_used_spend).reduce((acc, item) => {
        acc += +item.price
        return acc
      }, 0)
      convArr.spend_2 = Object.values(data.not_used_spend).reduce((acc, item) => {
        acc += +item.price_2
        return acc
      }, 0)
      convArr.new_deposits = 'empty'
    }
    data.rows.forEach(row => { // make empty fields for multi group
      row.id = generatePassword(8)
      if(defineGroupId(filters.filter.secondGroupBy) !== defineGroupId(firstGroupBy))
        row.groups[defineGroupId(filters.filter.secondGroupBy)] = null
      if(defineGroupId(filters.filter.thirdGroupBy) !== defineGroupId(firstGroupBy))
        row.groups[defineGroupId(filters.filter.thirdGroupBy)] = null
      if(defineGroupId(filters.filter.fourthGroupBy) !== defineGroupId(firstGroupBy))
        row.groups[defineGroupId(filters.filter.fourthGroupBy)] = null
      if ([
        defineGroupId(filters.filter.secondGroupBy),
        defineGroupId(filters.filter.thirdGroupBy),
        defineGroupId(filters.filter.fourthGroupBy),
      ].includes('player_id')) row.groups.click_id = null
      if (firstGroupBy === 'promocode') {
        row.groups.promocode = row.groups.promocode || 'No promocode'
      } else if (firstGroupBy === 'month') {
        row.groups.month = `${row.groups.month_title || ''} ${row.groups.year || ''}`
      } else if (firstGroupBy === 'main_manager') {
        row.groups.manager_name += ` ${row.groups?.manager_last_name || ''}${
          row.groups?.partner_group ? `, ${row.groups?.partner_group}` : ''}`
      } else if (firstGroupBy === 'offer') {
        row.groups.offer = `${row.groups.offer || ''}${row.groups.advertiser ? `, ${row.groups.advertiser}` : ''}${
          row.groups?.country ? `, ${row.groups?.country}` : ''}${
          row.groups?.product ? `, ${row.groups?.product}` : ''}${
          row.groups?.payment_model ? `, ${row.groups?.payment_model}` : ''}`
      } else if (firstGroupBy === 'site_id') {
        row.groups.site_id = `${row.groups.site_id || ''}${row.groups.advertiser ? `, ${row.groups.advertiser}` : ''}${
          row.groups?.partner ? `, ${row.groups?.partner}` : ''}${
          row.groups?.stream ? `, ${row.groups?.stream}` : ''}`
      } else if (firstGroupBy === 'partner') {
        row.groups.partner = `${row.groups.partner || ''}${row.groups.partner_tag ? `, ${row.groups.partner_tag}` : ''}`
      }
      if (filters.filter.secondGroupBy) row.second_group.forEach(subRow => {
        subRow.id = generatePassword(8)
        Object.keys(subRow.groups).forEach(key => {
          if (['offer_tag', 'partner_tag'].includes(key)) return
          if (!subRow.groups[key] && !key.includes('_id')) {
            subRow.groups[key] = '<not selected>'
            subRow.groups[key + '_id'] = null
          }
        })
        if(defineGroupId(filters.filter.secondGroupBy) !== defineGroupId(firstGroupBy))
          subRow.groups[defineGroupId(firstGroupBy)] = null
        if(defineGroupId(filters.filter.secondGroupBy) !== defineGroupId(filters.filter.thirdGroupBy))
          subRow.groups[defineGroupId(filters.filter.thirdGroupBy)] = null
        if(defineGroupId(filters.filter.secondGroupBy) !== defineGroupId(filters.filter.fourthGroupBy))
          subRow.groups[defineGroupId(filters.filter.fourthGroupBy)] = null
        if (filters.filter.secondGroupBy === 'site_id_sub_id')
          subRow.groups.site_id_sub_id = `${subRow.groups.site_id} (${subRow.groups.sub_id || '-'})`
        if (filters.filter.secondGroupBy === 'click_id') {
          subRow.click_count = ''
          subRow.click_reg = ''
        }
        if (filters.filter.secondGroupBy === 'month')
          subRow.groups.month = `${subRow.groups.month_title} ${subRow.groups.year}`
        if (filters.filter.secondGroupBy === 'offer') {
          subRow.groups.offer = `${subRow.groups.offer || ''} ${subRow.groups.advertiser ? `, ${subRow.groups.advertiser}` : ''}`
        }
        if ([
          defineGroupId(filters.filter.thirdGroupBy),
          defineGroupId(filters.filter.fourthGroupBy),
        ].includes('player_id')) subRow.groups.click_id = null
        if (filters.filter.thirdGroupBy) subRow.third_group.forEach(item => {
          item.id = generatePassword(8)
          Object.keys(item.groups).forEach(key => {
            if (['offer_tag', 'partner_tag'].includes(key)) return
            if (!item.groups[key]) {
              item.groups[key] = '<not selected>'
              item.groups[key + (key === 'traffic' ? '_source_id' : '_id')] = null
            }
          })
          if(defineGroupId(filters.filter.thirdGroupBy) !== defineGroupId(firstGroupBy))
            item.groups[defineGroupId(firstGroupBy)] = null
          if(defineGroupId(filters.filter.thirdGroupBy) !== defineGroupId(filters.filter.secondGroupBy))
            item.groups[defineGroupId(filters.filter.secondGroupBy)] = null
          if(defineGroupId(filters.filter.thirdGroupBy) !== defineGroupId(filters.filter.fourthGroupBy))
            item.groups[defineGroupId(filters.filter.fourthGroupBy)] = null
          if (filters.filter.thirdGroupBy === 'site_id_sub_id')
            item.groups.site_id_sub_id = `${item.groups.site_id} (${item.groups.sub_id || '-'})`
          if (filters.filter.thirdGroupBy === 'click_id') {
            item.click_count = ''
            item.click_reg = ''
          }
          if (filters.filter.thirdGroupBy === 'month')
            item.groups.month = `${item.groups.month_title} ${item.groups.year}`
          if (filters.filter.thirdGroupBy === 'offer') {
            item.groups.offer = `${item.groups.offer || ''} ${item.groups.advertiser ? `, ${item.groups.advertiser}` : ''}`
          }
          if ([
            defineGroupId(filters.filter.fourthGroupBy)
          ].includes('player_id')) item.groups.click_id = null
          if (filters.filter.fourthGroupBy) item.fourth_group.forEach(subItem => {
            subItem.id = generatePassword(8)
            Object.keys(subItem.groups).forEach(key => {
              if (['offer_tag', 'partner_tag'].includes(key)) return
              if (!subItem.groups[key]) {
                subItem.groups[key] = '<not selected>'
                subItem.groups[key + (key === 'traffic' ? '_source_id' : '_id')] = null
              }
            })
            if(defineGroupId(filters.filter.fourthGroupBy) !== defineGroupId(firstGroupBy))
              subItem.groups[defineGroupId(firstGroupBy)] = null
            if(defineGroupId(filters.filter.fourthGroupBy) !== defineGroupId(filters.filter.secondGroupBy))
              subItem.groups[defineGroupId(filters.filter.secondGroupBy)] = null
            if(defineGroupId(filters.filter.fourthGroupBy) !== defineGroupId(filters.filter.thirdGroupBy))
              subItem.groups[defineGroupId(filters.filter.thirdGroupBy)] = null
            if (filters.filter.fourthGroupBy === 'site_id_sub_id')
              subItem.groups.site_id_sub_id = `${subItem.groups.site_id} (${subItem.groups.sub_id || '-'})`
            if (filters.filter.fourthGroupBy === 'click_id') {
              subItem.click_count = ''
              subItem.click_reg = ''
            }
            if (filters.filter.fourthGroupBy === 'month')
              subItem.groups.month = `${subItem.groups.month_title} ${subItem.groups.year}`
            if (filters.filter.fourthGroupBy === 'offer') {
              subItem.groups.offer = `${subItem.groups.offer || ''} ${subItem.groups.advertiser ? `, ${subItem.groups.advertiser}` : ''}`
            }
          })
        })
      })
    })
    state.list = [...data.rows]
    state.notUsedSpend = Object.values(data.not_used_spend).length ? [convArr] : []
    state.total = data.total
    state.playersCount = data.player_count
    const sortOptions = checkSortBy(filters)
    state.tableOptions = new TableOptions(pagination, filters)
    if(['date', 'week', 'month'].includes(filters.filter.groupBy)){
      store.dispatch('CohortAnalysisModule/updateSortGeneralReport', {...filters, ...sortOptions})
    }
  },
  setReportData(state, {data, filters}){
    const reportData = cloneDeep(data.rows)
    const firstGroupBy = filters.filter.groupBy
    reportData.forEach((row) => {
      if (firstGroupBy === 'main_manager') {
        const resString = ` ${row.groups?.manager_last_name || ''}${
          row.groups?.partner_group ? `, ${row.groups?.partner_group}` : ''}`
        row.groups.manager_name += resString
        if(row?.second_group?.length) row.second_group.forEach(subRow => {
          subRow.groups.manager_name += resString
          if(subRow?.third_group?.length) subRow.third_group.forEach(third => {
            third.groups.manager_name += resString
            if(third?.fourth_group?.length) third.fourth_group.forEach(fourth => {
              fourth.groups.manager_name += resString
            })
          })
        })
      } else if (firstGroupBy === 'month') {
        const resString = `${row.groups.month_title || ''} ${row.groups.year || ''}`
        row.groups.month = resString
        if(row?.second_group?.length) row.second_group.forEach(subRow => {
          subRow.groups.month = resString
          if(subRow?.third_group?.length) subRow.third_group.forEach(third => {
            third.groups.month = resString
            if(third?.fourth_group?.length) third.fourth_group.forEach(fourth => {
              fourth.groups.month = resString
            })
          })
        })
      } else if (firstGroupBy === 'promocode') {
        row.groups.promocode = row.groups.promocode || 'No promocode'
        if(row?.second_group?.length) row.second_group.forEach(subRow => {
          subRow.groups.promocode = row.groups.promocode || 'No promocode'
          if(subRow?.third_group?.length) subRow.third_group.forEach(third => {
            third.groups.promocode = row.groups.promocode || 'No promocode'
            if(third?.fourth_group?.length) third.fourth_group.forEach(fourth => {
              fourth.groups.promocode = row.groups.promocode || 'No promocode'
            })
          })
        })
      } else if (firstGroupBy === 'offer') {
        const resString = `${row.groups.offer || ''}${row.groups.advertiser ? `, ${row.groups.advertiser}` : ''}${
          row.groups?.country ? `, ${row.groups?.country}` : ''}${
          row.groups?.product ? `, ${row.groups?.product}` : ''}${
          row.groups?.payment_model ? `, ${row.groups?.payment_model}` : ''}`
        row.groups.offer = resString
        if(row?.second_group?.length) row.second_group.forEach(subRow => {
          subRow.groups.offer = resString
          if(subRow?.third_group?.length) subRow.third_group.forEach(third => {
            third.groups.offer = resString
            if(third?.fourth_group?.length) third.fourth_group.forEach(fourth => {
              fourth.groups.offer = resString
            })
          })
        })
      } else if (firstGroupBy === 'site_id') {
        const resString = `${row.groups.site_id || ''}${row.groups.advertiser ? `, ${row.groups.advertiser}` : ''}${
          row.groups?.partner ? `, ${row.groups?.partner}` : ''}${
          row.groups?.stream ? `, ${row.groups?.stream}` : ''}`
        row.groups.site_id = resString
        if(row?.second_group?.length) row.second_group.forEach(subRow => {
          subRow.groups.site_id = resString
          if(subRow?.third_group?.length) subRow.third_group.forEach(third => {
            third.groups.site_id = resString
            if(third?.fourth_group?.length) third.fourth_group.forEach(fourth => {
              fourth.groups.site_id = resString
            })
          })
        })
      } else if (firstGroupBy === 'partner') {
        const resString = `${row.groups.partner || ''}${row.groups.partner_tag ? `, ${row.groups.partner_tag}` : ''}`
        row.groups.partner =resString
        if(row?.second_group?.length) row.second_group.forEach(subRow => {
          subRow.groups.partner = resString
          if(subRow?.third_group?.length) subRow.third_group.forEach(third => {
            third.groups.partner = resString
            if(third?.fourth_group?.length) third.fourth_group.forEach(fourth => {
              fourth.groups.partner = resString
            })
          })
        })
      }
    })
    state.listReport = [...reportData]
  },
  setPaginatedList(state, {data, pagination, filters}) {
    state.paginatedList = [...data.rows]
    state.total = data.total
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = {...filters}
  },
  setPagination(state, pagination) {
    state.tableOptions = {
      ...state.tableOptions,
      page: pagination.page,
      pagination,
      totalItems: Math.ceil(state.tableOptions.total / pagination.limit),
    }
  },
  setSort(state, {sortBy, sortDesc}) {
    state.tableOptions = {
      ...state.tableOptions,
      sortBy,
      sortDesc,
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
