import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import i18n from '@/plugins/vue-i18n'
import generatedRoutes from "./generated-routes";
import auth from './auth'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    ...auth,
    {
      path: '/',
      component: () => import('@/layouts/LayoutDefault.vue'),
      children: [
        {
          path: '/',
          redirect: '/offers',
        },
        ...generatedRoutes,
        {
          path: '/403',
          name: 'pageError403',
          meta: { title: 'page.pageError403', noRedirect: true },
          component: () => import('@/pages/error/Error-403.vue'),
        },
        {
          path: '/404',
          name: 'pageError404',
          meta: { title: 'page.pageError404', noRedirect: true },
          component: () => import('@/pages/error/Error-404.vue'),
        },
      ],
    },

    // 404 page must be placed at the end !!!
    { path: '*', redirect: '/404', hidden: true },
  ],
})

const whiteList = ['loginPage', 'restorePage', 'recoveryPassword', 'qrCodePage'] // no redirect whitelist

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  store.dispatch('AuthModule/onVerifyAuth').then(() => {
    const isAuthenticated = store.getters['AuthModule/isAuthenticated']
    if (isAuthenticated && whiteList.includes(to.name)) {
      next('/offers')
      return
    }

    if (whiteList.includes(to.name)) {
      next()
      return
    }

    if (!isAuthenticated) {
      next({ name: 'loginPage', query: to.meta?.noRedirect ? {} : { path: to.path, ...to.query } })
      return
    }

    // redirect on 403 when no permission
    const profilePermissions = store.getters['ProfileModule/profile'].permissions
    const profileRole = store.getters['ProfileModule/profile'].role
    const hasAccessPermission =
      !to.meta.permissions?.length ? false :
      !Array.isArray(to.meta.permissions) ||
      to.meta.permissions.some(permission => profilePermissions.includes(permission))
    const hasAccessRole =
      !Array.isArray(to.meta.roles) || to.meta.roles.includes(profileRole)

    const hasAccessPage = hasAccessPermission || hasAccessRole
    if (!hasAccessPage) {
      next({ name: 'pageError403' })
      return
    }

    next()
  })

  // close modals on change route
  if (to.name !== from.name) store.dispatch('ModalModule/closeAll')

  // set page tab name
  document.title = i18n.t(`${to.meta.title}`)
})

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router
